const Nav = () => {
  return (
    <nav className="nav">
      <ul>
        <li>
          <a href="/">NODULES OF FLINT</a>
        </li>
        <li>
          <a href="/about">find out more...</a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
