import Nav from "./components/Nav";
import ImageGrid from "./components/ImageGrid";

function Homepage() {
  return (
    <>
      <Nav />
      <ImageGrid />
    </>
  );
}

export default Homepage;
