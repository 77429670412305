function About() {
  return (
    <section className="about">
      <section className="about-container">
        <a tabindex="0" href="/">
          <img className="arrow" alt="Go back to homepage" src="/arrow.svg" />
        </a>
        <p>
          Nodules of Flint is a project by designer & photographer Paige Scalco, documenting stones
          collected from Brighton Beach.
        </p>
        <p>
          This website is an archive of the collection, aimed to create an accessible platform for
          passers’ by to explore, appreciate and be inspired by their simple beauty.
        </p>
        <p className="about-website">Website built by Lee Doughty, 2021.</p>
      </section>
    </section>
  );
}

export default About;
